<template>
    <div class="contact_us_container">
      <div class="contact_us_box border_text_box container_width">
        <h2 class="border_text">Contact us</h2>
        <h3 class="border_text_title">联系我们</h3>
        <ul class="company_infobox">
          <li>
            <div class="title_box">
              <span><img src="../assets/imgs/home/home_address.webp" alt="" class="ic_img">地址</span>
            </div>
            <div class="company_text">湖北省武汉市洪山区 雄楚大道万科中心502</div>
          </li>
          <li>
            <div class="title_box">
              <span><img src="../assets/imgs/home/home_phone.webp" alt="" class="ic_img">电话</span>
            </div>
            <div class="company_text">13588762602</div>
          </li>
          <li>
            <div class="title_box">
              <span><img src="../assets/imgs/home/home_mail.webp" alt="" class="ic_img">邮箱</span>
            </div>
            <div class="company_text">boss@haianyd.com</div>
          </li>
          <li>
            <div class="title_box">
              <span><img src="../assets/imgs/home/home_url.webp" alt="" class="ic_img">网址</span>
            </div>
            <div class="company_text">wuhanxianniu.com</div>
          </li>
          <li>
            <div class="title_box">
              <span><img src="../assets/imgs/home/hoem_jmessage.webp" alt="" class="ic_img">给我们留言</span>
            </div>
            <div class="company_text">
              <input type="text"  placeholder="姓名">
              <input type="text"  placeholder="e-mail">
              <textarea placeholder="你想对我们说的话"></textarea>
            </div>
          </li>
        </ul>
        <div class="a_button">立即发送</div>
      </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css'
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
export default {

}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
@import '../assets/iconfont/iconfont.css';

.contact_us_container {
    .contact_us_box {
        text-align: center;
        padding-top: 50px;
        .border_text_title {
            margin-bottom: 24px;
        }
        .company_infobox {
            @include flexrl();
            flex-wrap: wrap;
            li {
                width: 34%;
                text-align: left;
                margin-bottom: 40px;
                .title_box {
                    margin-bottom: 15px;
                    .ic_img {
                        width: 33px;
                        margin-right: 20px;
                    }
                    span {
                        font-size: 32px;
                        font-weight: 700;
                        color: #161616;
                        line-height: 32px;
                    }
                }
                .company_text {
                    font-size: 20px;
                    color: #464949;
                    line-height: 20px;
                }
                .company_text:hover {
                    color: #356AE6;
                }
            }
            li:last-child {
                width: 100%;
                margin-bottom: 20px;
                .title_box {
                    margin-bottom: 40px;
                }
                .company_text {
                    @include flexrl();
                    flex-wrap: wrap;
                    input {
                        width: 42%;
                        margin-bottom: 30px;
                        background: #f7f7f7;
                        border-radius: 4px;
                        height: 73px;
                        font-size: 24px;
                        padding: 0 33px;
                        border: none;
                    }
                    input::-webkit-input-placeholder {
                        color: #c4c4c4;
                        font-weight: bold;
                    }
                    input:focus {
                        background: #fff;
                        border: 1px solid;
                        border-image: linear-gradient(97deg, #73e0a9, #5b68df 100%) 1 1;
                    }
                    textarea {
                        width: 100%;
                        height: 200px !important;
                        resize: none;
                        margin-bottom: 30px;
                        background: #f7f7f7;
                        border-radius: 4px;
                        height: 73px;
                        font-size: 24px;
                        padding: 15px 33px 0;
                        border: none;
                    }
                    textarea::-webkit-input-placeholder {
                        color: #c4c4c4;
                        font-weight: bold;
                    }
                    textarea:focus {
                        background: #fff;
                        border: 1px solid;
                        border-image: linear-gradient(97deg, #73e0a9, #5b68df 100%) 1 1;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1160px) {
    .contact_us_container {
        .contact_us_box {
            .company_infobox {
                li:last-child {
                    .company_text {
                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .contact_us_container {
        .contact_us_box {
            .company_infobox {
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
                li {
                    width: 90%;
                }
            }
        }
    }
}
</style>